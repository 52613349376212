@font-face {
  font-family: "AktivGrotesk";
  src: local("AktivGrotesk"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Regular.woff)
      format("woff"),
    local("AktivGrotesk"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Regular.woff2)
      format("woff2"),
    local("AktivGrotesk"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Regular.ttf) format("ttf"),
    local("AktivGrotesk"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Regular.eot) format("eot");
}
@font-face {
  font-family: "AktivGrotesk-Medium";
  src: local("AktivGrotesk-Medium"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Medium.woff) format("woff"),
    local("AktivGrotesk-Medium"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Medium.woff2)
      format("woff2"),
    local("AktivGrotesk-Medium"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Medium.ttf) format("ttf"),
    local("AktivGrotesk-Medium"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Medium.eot) format("eot");
}
@font-face {
  font-family: "AktivGrotesk-Bold";
  src: local("AktivGrotesk-Bold"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Bold.eot) format("woff"),
    local("AktivGrotesk-Bold"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Bold.woff2) format("woff2"),
    local("AktivGrotesk-Bold"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Bold.ttf) format("ttf"),
    local("AktivGrotesk-Bold"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Bold.eot) format("eot");
}
@font-face {
  font-family: "AktivGrotesk-Light";
  src: local("AktivGrotesk-Light"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Light.woff) format("woff"),
    local("AktivGrotesk-Light"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Light.woff2)
      format("woff2"),
    local("AktivGrotesk-Light"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Light.ttf) format("ttf"),
    local("AktivGrotesk-Light"),
    url(./assets/fonts/AktivGrotesk/AktivGroteskCorp-Light.eot) format("eot");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"; */
  font-family: "AktivGrotesk";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.fixed {
  height: 100%;
  overflow: hidden;
}

.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6,
.MuiTableCell-root,
.MuiTypography-body1,
.MuiTab-root {
  font-family: "AktivGrotesk" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-success {
  color: #30b9a7 !important;
}

.text-danger {
  color: #e65a51 !important;
}

.text-right {
  text-align: right;
}

svg {
  vertical-align: initial;
}

input,
.MuiSelect-selectMenu,
label {
  font-size: 0.875rem !important;
}

label {
  color: #4a6282 !important;
  font-family: "AktivGrotesk" !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"; */
}

.form-group {
  margin-bottom: 0.2rem;
}

.invalid-feedback {
  display: block !important;
  min-height: 21px;
}

.invalid-feedback .icon {
  position: relative;
  top: 3px;
  margin-right: 8px;
}

/* Datepicker */
.MuiPickersDay-daySelected {
  background-color: #0073e7 !important;
  color: #fff !important;
}
.MuiPickersDay-day.MuiIconButton-root:hover {
  background-color: #e1efff;
}
.MuiPickersDay-current {
  color: #0073e7;
}

/* Chatbot style */
.iticks-pop-button {
  display: none !important;
}
